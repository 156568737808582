.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem;
  padding: 2rem;
  height: 96vh;
  min-height: 450px;
  text-align: center;
  border-radius: 9px;
  background: linear-gradient(
      89.59deg,
      rgba(0, 0, 0, 0.02) 0.32%,
      rgba(0, 0, 0, 0) 14.36%
    ),
    linear-gradient(
      122.58deg,
      rgba(77, 94, 71, 0.2) 16.74%,
      rgba(219, 223, 213, 0.2) 65.9%
    ),
    #daded4;
}

.link {
  cursor: pointer;
}

.link:hover {
  background: #95a89f;
  transition: all 0.5s;
}

svg {
  fill: #dbdfd5;
}

.desktop > svg {
  fill: #bdc5b8;
}

svg:hover {
  fill: #95a89f;
  cursor: pointer;
  transition: all 0.5s;
}

.button-first {
  background: none;
  border: 2px solid #3c413d;
  height: 59px;
  padding: 15px 30px;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  color: #3c413d;
}

.contact-left,
.contact-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 60px;
}

.contact-left {
  height: 60%;
}

.linkedin-container {
  color: #3c413d;
  padding: 0;
}

.top-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-left-text {
  margin-left: 10vh;
  text-transform: uppercase;
  color: #3c413d;
}

.contact-left-text > h1 {
  font-size: 96px;
  color: #3c413d;
  margin-bottom: 0;
}

.contact-left-text > h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  color: #3c413d;
  margin: 0;
}

.contact-left-text > h3 {
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
  color: #38603e;
  margin: 0;
}

.mobile-container {
  margin-top: 2vh;
}

.mobile-container > h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  color: #3c413d;
  margin: 0;
}

.contact-left-line {
  width: 87%;
  height: 3px;
  background: #38603e;
  border: 0;
}

.chairscan-container {
  width: 100%;
  height: 80%;
  padding-right: 45px;
  padding-bottom: 45px;
}

.contact-right-chairscan {
  height: 100%;
  object-fit: cover;
  box-shadow: 45px 45px 5px #95a89f;
}
.chairscan-container {
  margin-left: 2vh;
}

.c-mobile {
  display: none;
  padding-top: 2rem;
}

@media only screen and (max-width: 1200px) {
  .contact-left-text {
    padding: 0;
  }
}

@media only screen and (max-width: 1000px) {
  body,
  .contact {
    background: #dbdfd5;
  }

  .button-first {
    margin-bottom: 1rem
  }

  .contact {
    flex-direction: column;
    margin: 0;
    padding: 2rem 1rem;
    height: 100%;
  }
  .c-mobile {
    display: block;
    padding-top: 2rem;
  }

  .contact-left {
    padding: 0;
  }

  .contact-left-text {
    margin: 0;
  }

  .contact-left-text > h1 {
    font-size: 96px;
    margin-top: 0;
  }

  .contact-left-text > h2 {
    font-size: 36px;
  }

  .contact-left-text > h3 {
    font-size: 24px;
  }

  .contact-left-line {
    width: 70%;
  }

  .contact-right-chairscan {
    width: 70%;
  }
}
