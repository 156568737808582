.portfolio-content {
  box-sizing: border-box;
  margin: 1rem;
  padding: 2rem;
  text-align: center;
  border-radius: 9px;
  background: linear-gradient(
      89.59deg,
      rgba(0, 0, 0, 0.02) 0.32%,
      rgba(0, 0, 0, 0) 14.36%
    ),
    linear-gradient(
      122.58deg,
      rgba(77, 94, 71, 0.2) 16.74%,
      rgba(219, 223, 213, 0.2) 65.9%
    ),
    #daded4;
}

.carousel-template {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-image {
    border: 1px solid #4d5e47;
    max-width: 80%;
    max-height:800px;
}

.card {
    background: var(--surface-card);
    position: relative;
    width: 80%;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.portfolio-title {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 0;
}

.portfolio-title > h1 {
  font-size: 32px;
  color: #38603e;
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  margin-top: 0;
}

/*.img {*/
/*  max-width: 100%;*/
/*  height: auto;*/
/*  vertical-align: middle;*/
/*  display: inline-block;*/
/*}*/

.item-box {
  display: flex;
  justify-content: center;
  align-items: center;
  column-width: 80rem;
  transition: all 350ms ease;
  cursor: pointer;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.portfolio-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 350px;
  grid-auto-flow: dense;
}

.wide {
  grid-column: span 2;
}

.tall {
  grid-row: span 2;
}
.big {
  grid-column: span 2;
  grid-row: span 2;
}

.item-box:hover {
  filter: opacity(0.5);
}

.model {
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.8);
    transition: opacity .4s ease, visibility .4s ease, transofm .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model-open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model-img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model-open-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: transparent;
    color: #38603e;
    cursor: pointer;
}

.button-area-top{
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
}

.button-first{
    background: none;
    border: 2px solid #3c413d;
    height: 59px;
    padding: 15px 30px;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: #3c413d;
}
.button-second {
    margin-right: 20px;

}

.link {
    cursor: pointer;
}

.link:hover {
    background: #95a89f;
    transition: all 0.5s;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50px;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #4d5e47;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    color: #95a89f;
}

.p-carousel .p-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-icon {
    width: 2rem;
    height: 2rem;
}
@media only screen and (max-width: 800px) {
    .text-center {
        font-size: 14px;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        width: 0.8rem;
        height: 0.8rem;
    }

}

@media only screen and (max-width: 600px) {
    .button-second {
        margin-right: 0;
    }
    .portfolio-grid {
        display: flex;
        flex-direction: column;
    }
    .text-center {
        font-size: 10px;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        width: 0.5rem;
        height: 0.5rem;
    }
    .card {
        padding: 1rem;
        width: 100%;
        border-radius: 0;
        margin: 0;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;

    }
    .p-carousel-container {

    }
    .p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }


}
