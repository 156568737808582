.intro {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem;
  padding: 2rem;
  height: 96vh;
  min-height: 450px;
  text-align: center;
  border-radius: 9px;
  background: linear-gradient(
      89.59deg,
      rgba(0, 0, 0, 0.02) 0.32%,
      rgba(0, 0, 0, 0) 14.36%
    ),
    linear-gradient(
      122.58deg,
      rgba(77, 94, 71, 0.2) 16.74%,
      rgba(219, 223, 213, 0.2) 65.9%
    ),
    #daded4;
}

.link {
  cursor: pointer;
}

.link:hover {
  background: #95a89f;
  transition: all 0.5s;
}

svg {
  fill: #dbdfd5;
}

.desktop > svg {
  fill: #bdc5b8;
}

svg:hover {
  fill: #95a89f;
  cursor: pointer;
  transition: all 0.5s;
}

.intro-left,
.intro-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 45px;
}

.intro-left {
  height: 80%;
}

.linkedin-container {
  color: #3c413d;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.intro-left-text {
  padding-left: 10vh;
  text-transform: uppercase;
  color: #3c413d;
}

.intro-left-text > h1 {
  font-size: 128px;
  color: #3c413d;
  margin-bottom: 0;
}

.intro-left-text > h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 48px;
  color: #3c413d;
  margin: 0;
}

.intro-left-text > h3 {
  font-weight: 500;
  font-size: 32px;
  font-style: normal;
  color: #38603e;
  margin: 0;
}

.intro-left-line {
  width: 87%;
  height: 3px;
  background: #38603e;
  border: 0;
}

.portrait-container {
  width: 100%;
  height: 80%;
  padding-right: 45px;
  padding-bottom: 45px;
  padding-top: 15vh;
}

.intro-right-portrait {
  height: 100%;
  object-fit: cover;
  box-shadow: 45px 45px 5px #95a89f;
}

.contact-button-container {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-area {
  padding-left: 10vh;
  display: flex;
  justify-content: space-evenly;
}

.button-left {
  background: none;
  border: 2px solid #3c413d;
  width: 240px;
  height: 59px;
  padding-bottom: 15px;
  padding-top: 15px;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  color: #3c413d;
}

.button-right {
  background: #daded4;
  border: 3px solid #4d5e47;
  border-radius: 0 0 50px 0;
  width: 311px;
  height: 88px;
  padding-bottom: 15px;
  padding-top: 15px;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  color: #3c413d;
}

.button-right-span {
  font-size: 13px;
  color: #38603e;
}

.arrow-right {
  padding: 0 0 0 5px;
}
.mobile {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .intro-left-text {
    padding: 0;
  }

  .button-area {
    padding: 0;
  }
}

@media only screen and (max-width: 1000px) {
  body,
  .intro {
    background: #dbdfd5;
  }
  .intro {
    flex-direction: column;
    margin: 0;
    padding: 2rem 1rem;
    height: 100%;
    width: 100%;
  }

  .portrait-container {
    margin: 0 auto;
    width: 85%;
    padding-top: 0;
  }

  .contact-button-container {
    justify-content: space-around;
  }

  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }

  .intro-left-text > h1 {
    font-size: 96px;
    margin-top: 0;
  }

  .intro-left-text > h2 {
    font-size: 36px;
  }

  .intro-left-text > h3 {
    font-size: 24px;
  }

  .intro-left-line {
    width: 70%;
  }

  .button-area {
    margin: 3rem 0 2rem 0;
  }

  .intro-right-portrait {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .button-right {
    font-size: 18px;
  }
  .button-right-span {
    font-size: 11px;
  }
}
