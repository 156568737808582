* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.app {
  width: 100%;
  min-width: 412px;
  max-width: 1600px;
  margin: 0 auto;
}
