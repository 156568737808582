.cv-content {
  box-sizing: border-box;
  margin: 1rem;
  padding: 2rem;
  text-align: center;
  border-radius: 9px;
  background: linear-gradient(
      89.59deg,
      rgba(0, 0, 0, 0.02) 0.32%,
      rgba(0, 0, 0, 0) 14.36%
    ),
    linear-gradient(
      122.58deg,
      rgba(77, 94, 71, 0.2) 16.74%,
      rgba(219, 223, 213, 0.2) 65.9%
    ),
    #daded4;
}

.button-area-top {
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
}

.cv-button-first {
  background: none;
  border: 2px solid #3c413d;
  height: 59px;
  padding: 15px 30px;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  color: #3c413d;
}
.cv-button-second {
  margin-right: 20px;
  text-decoration: none;
}

.cv-link {
  cursor: pointer;
}

.cv-link:hover {
  background: #95a89f;
  transition: all 0.5s;
}

.cv-max {

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cv {
  width: 50%;
  padding: 20px;
}

@media only screen and (max-width: 1200px) {
  .cv-max {
    margin: 0;
  }
  .cv {
    width: 50%;
  }

  /*.cv-left-text {*/
  /*    padding: 0;*/
  /*}*/

  /*.button-area {*/
  /*    padding: 0;*/
  /*}*/
}

@media only screen and (max-width: 1000px) {
  .cv-content {
    background: #dbdfd5;
  }

  .cv-max {
    display: flex;
    flex-direction: column;
  }

  .cv {
    width: 100%;
  }

  .button-area-top {
    justify-content: space-evenly;
  }

  .cv-button-first {
    font-size: 21px;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 600px) {

  .button-area-top {
    margin: 0;
    flex-direction: column;
  }

  .cv-button-first {
    margin: 10px;
  }

  .cv-button-second {
    margin-bottom: 1rem;
  }
}
